<template>
    <OAutoComplete ref="autocompleteRef" :id="autocompleteId" v-if="autocompleteDataObject" :dataObject="autocompleteDataObject" :value="filterItem.expressionValue ?? filterItem.selectedValue" 
        :field="autocompleteDisplayField" class="text-truncate d-flex flex-1" wrapperClass="text-truncate d-flex flex-1" :filterOperator="autocompleteFilterOperator"
        :bind="onSelected" :disableFirstItemAutoSelect="!existingValuesOnly || autocompleteOptions.disableAutoSelect" :disableCloseOnEnter="existingValuesOnly" @enter:input="onRawEnter" @blur:noSelection="onBlur"
        :data-bs-title="existingValuesOnly ? $t('Only existing values allowed') : undefined">
        <template #default="{row}">
            {{row[autocompleteDisplayField]}}
        </template>
    </OAutoComplete>
    <OAutoComplete ref="autocompleteRef" :id="autocompleteId" v-else :value="filterItem.expressionValue ?? filterItem.selectedValue" field="display" class="text-truncate d-flex flex-1" wrapperClass="text-truncate d-flex flex-1" 
        :bind="onSelected" :getData="getData" :disableFirstItemAutoSelect="!existingValuesOnly || autocompleteOptions.disableAutoSelect" :disableCloseOnEnter="existingValuesOnly" @enter:input="onRawEnter" @blur:noSelection="onBlur"
        :data-bs-title="existingValuesOnly ? $t('Only existing values allowed') : undefined">
        <template #default="{row}">
            {{row.display}}
        </template>
    </OAutoComplete>
</template>

<script setup lang="ts">
import type FilterItem from './FilterItem.ts';
import type FilterObject from './FilterObject.ts';

import { OAutoComplete } from 'o365-data-components';
import { logger } from 'o365-utils';
import { computed, ref } from 'vue'; 

const props = defineProps<{
    filterItem: FilterItem
    filterObject: FilterObject
}>();

const uid = window.crypto.randomUUID();

const autocompleteRef = ref<InstanceType<typeof OAutoComplete>>();

const autocompleteOptions = computed(() => props.filterItem?.options?.filterParams?.autocomplete);
// TODO: Change all computed properties usage to autocompleteOptions 
const existingValuesOnly = computed(() => props.filterItem?.options?.filterParams?.autocomplete?.existingValuesOnly ?? false);
const autocompleteId = computed(() => `${props.filterObject.dataObject.id}_${props.filterItem?.options?.name}-${uid}`);
const autocompleteFilterOperator = computed(() => props.filterItem?.options?.filterParams?.autocomplete?.filterOperator ?? undefined);
const autocompleteField = computed(() => props.filterItem?.options?.filterParams?.autocomplete?.field);
const autocompleteDisplayField = computed(() => props.filterItem?.options?.filterParams?.autocomplete?.displayField ?? autocompleteField.value);
const autocompleteValueField = computed(() => props.filterItem?.options?.filterParams?.autocomplete?.valueField ?? autocompleteField.value);
const autocompleteDataObject = computed(() => {
    const dataObjectId = props.filterItem?.options?.filterParams?.autocomplete?.dataObjectId;
    const appId = props.filterItem?.options?.filterParams?.autocomplete?.appId;
    if (dataObjectId && appId) {
        return $getDataObjectById(dataObjectId, appId)
    } 
});

function onBlur(_pEvent, pValue, pSelectableValue) {
    if (pSelectableValue != null) {
        onSelected(pSelectableValue);
        return;
    } 
    if (existingValuesOnly.value && pValue) { return; }
    props.filterItem.useAlias = false;
    props.filterItem.selectedValue = pValue;
    props.filterItem.expressionValue = pValue;
    // props.filterItem.operator = props.filterItem.defaultOperator;
    props.filterObject.apply();
}

let tooltipDebounce: number | null = null;
async function onRawEnter(_pEvent, pValue) {
    const showErrorMessage = () => {
        window.requestAnimationFrame(() => {
            try {
                const inputEl = document.getElementById(autocompleteId.value);
                const tooltip = window.bootstrap.Tooltip.getOrCreateInstance(inputEl);
                tooltip.enable();
                tooltip.show();
                if (tooltipDebounce) { window.clearTimeout(tooltipDebounce); }
                tooltipDebounce = window.setTimeout(() => {
                    try {
                        tooltip.hide();
                        tooltip.disable();
                        tooltip.dispose();
                    } catch (ex) {
                        logger.error(ex);
                    }
                }, 1500);
            } catch (ex) {
                logger.error(ex);
            }
        });
    }

    const targetField = getTargetField();
    const displayField = getDisplayField();
    const customFilterFunction = props.filterItem?.options?.filterParams?.autocomplete?.beforeApply;
    if (props.filterItem.operator === 'exists_clause') {
        props.filterItem.operator = 'contains';
    }
    if (autocompleteOptions.value?.awaitForAutocomplete && autocompleteRef.value.autocompleteControl.loadingPromise) {
        await autocompleteRef.value.autocompleteControl.loadingPromise;
    }
    if (pValue && props.filterItem.operator === 'contains' && (targetField != displayField || customFilterFunction) && autocompleteRef.value.autocompleteControl.loadingPromise == null && autocompleteRef.value?.autocompleteControl?.fullData.length > 0) {
        props.filterItem.useAlias = targetField != displayField;
        props.filterItem.operator = 'inlist';
        props.filterItem.selectedValue = autocompleteRef.value?.autocompleteControl?.fullData.map(row => getValue(row));
        props.filterItem.expressionValue = props.filterItem.selectedValue;
       // props.filterItem.expressionValue = autocompleteRef.value?.autocompleteControl?.fullData.map(row => getDisplay(row));
        if (customFilterFunction) {
            customFilterFunction({
                selected: autocompleteRef.value?.autocompleteControl?.fullData,
                filterItem: props.filterItem,
                value: props.filterItem.selectedValue,
                display: props.filterItem.expressionValue
            })
        }
    } else {
        if (existingValuesOnly.value && pValue) {
            showErrorMessage();
            return;
        }
        props.filterItem.useAlias = false;
        props.filterItem.selectedValue = pValue;
        props.filterItem.expressionValue = pValue;
    }
    // props.filterItem.operator = props.filterItem.defaultOperator;
    props.filterObject.apply();
    if (autocompleteRef.value?.autocompleteControl?.dropdown?.isOpen) {
        autocompleteRef.value.autocompleteControl.dropdown.close();
    }
}

function getTargetField() {
    return props.filterItem.distinctHandler.distinctTargetColumn ?? props.filterItem.distinctHandler.distinctColumn ?? props.filterItem.distinctHandler.targetColumn ?? props.filterItem.distinctHandler.column;
}

function getDisplayField() {
    return props.filterItem.distinctHandler.distinctColumn ?? (props.filterItem.distinctHandler.targetColumn ? props.filterItem.distinctHandler.column : null) ?? getTargetField();
}

function getDisplay(pSel) {
    if (autocompleteDataObject.value) {
        return pSel[autocompleteDisplayField.value];
    } else {
        return pSel.display;
    }
}

function getValue(pSel) {
    if (autocompleteDataObject.value) {
        return pSel[autocompleteValueField.value];
    } else {
        return pSel.value;
    }
}

function onSelected(pSel) {
    
    const targetField = getTargetField();
    const displayField = getDisplayField();
    const customFilterFunction = props.filterItem?.options?.filterParams?.autocomplete?.beforeApply;
    if (targetField === displayField) {
        props.filterItem.useAlias = false;
        props.filterItem.operator = 'equals';
        props.filterItem.selectedValue = getValue(pSel);
        props.filterItem.expressionValue = getDisplay(pSel);
    } else {
        props.filterItem.useAlias = true;
        props.filterItem.operator = 'equals';
        const vValue = getValue(pSel);
        if(typeof vValue == 'number'){
            props.filterItem.selectedValue = getDisplay(pSel);
            
        }else{
           props.filterItem.selectedValue = vValue;
        }
     
        props.filterItem.expressionValue = getDisplay(pSel);
    }
    if(props.filterItem.existsObject){
        props.filterItem.operator = 'exists_clause';
    }

    if (customFilterFunction) {
        customFilterFunction({
            selected: pSel,
            filterItem: props.filterItem,
            value: getValue(pSel),
            display: getDisplay(pSel)
        })
    }


    props.filterObject.apply();
}

function searchPredicate(a: string, b:string) {
    const lowerA = (a ?? 'Blank').toLocaleLowerCase();
    const lowerB = (b ?? 'Blank').toLocaleLowerCase();
    return lowerA.includes(lowerB);
}

async function getData(pSearchString: string) {
    props.filterItem.distinctHandler.setDataObject(props.filterObject.dataObject);
    const targetField = getTargetField();
    const displayField = getDisplayField();
    const useDistinctDefinitinoProc = !!props.filterItem.distinctHandler.definitionProc;
    if( props.filterItem.distinctHandler.dataLoaded && props.filterItem.distinctHandler.search && !useDistinctDefinitinoProc){
        props.filterItem.distinctHandler.dataLoaded = false;
        props.filterItem.distinctHandler.search = null;
    }
    let originalSearch = '';
    if (useDistinctDefinitinoProc) {
        originalSearch = props.filterItem.distinctHandler.search;
        props.filterItem.distinctHandler.search = pSearchString ? pSearchString : '';
    }
    const data: any[] = await props.filterItem.distinctHandler.getData();
    const result = data.filter(x => searchPredicate(x[displayField], pSearchString)).map(item => ({
        display: item[displayField] ?? 'Blank',
        value: item[targetField] ?? 'Blank',
    }));
    if (useDistinctDefinitinoProc) {
        props.filterItem.distinctHandler.dataLoaded = false;
        props.filterItem.distinctHandler.search = originalSearch;
    }
    return result;
}

</script>